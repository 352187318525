import * as React from 'react';

import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkText } from 'uitk-react-text';
import { UitkLink } from 'uitk-react-link';

import { useStores } from 'src/hooks';
import { makeLocaleParam } from 'src/util';

export function Footer() {
  const { context } = useStores();

  const localeParam = makeLocaleParam(context.locale);

  return (
    <div className="CVFooter">
      <div className="CVContainer">
        <UitkLayoutFlex justifyContent="space-between" space="six" className="CVFooter__content">
          <UitkLayoutFlex className="CVFooter__links" alignItems="center" wrap="wrap" space="six">
            <UitkLink inline className="CVFooter__button">
              <UitkText size={200}>
                <a href={`https://affiliates.expediagroup.com/lp/b/affhub/affiliate?siteid=2055&locale=${localeParam}`} role="link">Terms & conditions</a>
              </UitkText>
            </UitkLink>
            <UitkLink inline className="CVFooter__button">
              <UitkText size={200}>
                <a href={`/${localeParam}/travel-video/video-guidelines`} role="link">Video content guidelines</a>
              </UitkText>
            </UitkLink>
            <UitkLink inline className="CVFooter__button">
              <UitkText size={200}>
                <a href="mailto:creatorvideosupport@expediagroup.com">Contact us</a>
              </UitkText>
            </UitkLink>
          </UitkLayoutFlex>
          <UitkText size={200}>© 2024 Expedia, Inc. All rights reserved. Confidential and proprietary.</UitkText>
        </UitkLayoutFlex>
      </div>
    </div>
  );
}
