// 3rd party imports
import * as React from 'react';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

// shared-ui
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';

// ui
import { GlobalNavigationSiteHeader } from 'uitk-react-global-navigation';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLayoutPosition } from 'uitk-react-layout-position';
import { UitkLink } from 'uitk-react-link';
import { UitkSpacing } from 'uitk-react-spacing';

import { SigninAndJoinNav } from 'shared/layout/signin-and-join-nav';

import { useStores } from 'src/hooks';
import { EventType, headerLogoSelectedEvent, makeLocaleParam, useCommonClickStreamEvent } from 'src/util';

import { UitkTertiaryButton } from 'uitk-react-button';

const navItems = [
  {
    label: 'Benefits',
    anchor: '#benefits',
  },
  {
    label: 'Inspiration',
    anchor: '#inspiration',
  },
  {
    label: 'How it works',
    anchor: '#how-it-works',
  },
  {
    label: 'Tips',
    anchor: '#tips',
  },
  {
    label: 'FAQ',
    anchor: '#faq',
  },
];

const Header: FunctionComponent = () => {
  const track = useEgClickstream();
  const { trackEvent } = useCommonClickStreamEvent();
  const { context } = useStores();

  const handleAnchorClick = (anchor) => {
    const anchorText = anchor.replace('#', '');
    trackEvent(
      {
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_redirect_to_section.selected',
        action_location: `header-nav-${anchorText}`,
      },
      { additional_context: { user_interface: { component_name: 'header-nav', component_element_text: anchorText } } },
    );
  };

  return (
    <UitkLayoutPosition type="sticky" position={{ top: 'zero', zindex: 'layer1' }}>
      <GlobalNavigationSiteHeader className="Header">
        <UitkSpacing padding={{ small: { inline: 'six' } }}>
          <UitkLayoutFlex alignItems="center" justifyContent="space-between">
            <UitkLayoutFlexItem>
              <div className="CVHeader__logo">
                <UitkLink onClickCapture={() => headerLogoSelectedEvent(track)}>
                  <Link to={`/${makeLocaleParam(context.locale)}/travel-video`}>
                    <img src="/images/logos/eg-affiliate-program-logo-blue.svg" alt="Expedia Group Affiliate Program" />
                  </Link>
                </UitkLink>
              </div>
            </UitkLayoutFlexItem>
            <div className="show-desktop">
              <UitkLayoutFlex alignItems="center" space="three" className="TVHeader__nav">
                {navItems.map((link, ind) => {
                  return (
                    <UitkTertiaryButton onClick={() => handleAnchorClick(link.anchor)} key={`header-nav-${ind}`}>
                      <a href={link.anchor}>{link.label}</a>
                    </UitkTertiaryButton>
                  );
                })}
              </UitkLayoutFlex>
            </div>
            <UitkLayoutFlexItem>
              <nav className="Header__nav">
                <UitkLayoutFlex
                  alignItems="center"
                  className="no-bullet"
                  direction="row"
                  space="three"
                  tag="ul"
                  wrap="wrap"
                >
                  <li>
                    <SigninAndJoinNav />
                  </li>
                </UitkLayoutFlex>
              </nav>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        </UitkSpacing>
      </GlobalNavigationSiteHeader>
    </UitkLayoutPosition>
  );
};

export { Header };
